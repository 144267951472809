const initMobileNav = () => {

    //Opens and closes the mobile side panel, which is just Main Menu and Utility Menu
    document.querySelector(".mobile-bar .navicon").addEventListener("click", () => {
        let headerElement = document.querySelector(".home-header");
        let navIconContainer = document.querySelector(".navicon.ummshome");
        if (headerElement !== null) {
            toggleClass(headerElement, "open");
        }

        if (navIconContainer !== null) {
            toggleClass(navIconContainer, "open");
        }
    });

    // Closes the mobile search panel
    document.querySelectorAll(".search-icon-mobile>svg").forEach(el => {
        el.addEventListener("click", (e) => {

            let searchElement = document.querySelector(".home-mobile-search-panel");
            if (searchElement !== null) {
                toggleClass(searchElement, "open");
            }

            // we toggle the close class on the svg item
            // so, when a user clicks the search icon, we hide it and show the close icon
            // and, vice versa... when the the user clicks the close icon, we hide it and show the search icon
            // an easy way to do this is to remove the close class on all of them and then add it to the target element here
            document.querySelectorAll(".search-icon-mobile > svg").forEach(el => el.classList.remove("close"));
            el.classList.add("close");
        })
    });

    // Closes the mobile search panel
    document.querySelectorAll(".search-icon-desktop>svg").forEach(el => {
        el.addEventListener("click", (e) => {

            let searchElement = document.querySelector(".home-search-panel");
            if (searchElement !== null) {
                toggleClass(searchElement, "open");
            }

            // we toggle the close class on the svg item
            // so, when a user clicks the search icon, we hide it and show the close icon
            // and, vice versa... when the the user clicks the close icon, we hide it and show the search icon
            // an easy way to do this is to remove the close class on all of them and then add it to the target element here
            document.querySelectorAll(".search-icon-desktop> svg").forEach(el => el.classList.remove("close"));
            el.classList.add("close");
        })
    });

    // Validates search input if left blank
    document.querySelectorAll(".searchButton").forEach(el => {
        el.addEventListener("click", (e) => {
            var parent = e.currentTarget.parentElement;
            var formElement = parent.parentElement;
            let searchDiv = parent.getElementsByClassName("search-query")[0].value;
            let searchErrorMsgDiv = formElement.getElementsByClassName("searchErrMsg")[0];

            if (searchDiv) {
                searchErrorMsgDiv.innerHTML = "";
                searchErrorMsgDiv.classList.add("hide");
                formElement.submit();
            } else {
                searchErrorMsgDiv.innerHTML = "Please enter search text";
                searchErrorMsgDiv.classList.remove("hide");
                e.preventDefault();
                return false;
            }
        })
    });

    // Expands and collapses mobile sub menus
    document.querySelectorAll(".mobile-toggle-nav-btn").forEach(el => el.addEventListener("click", function (e) {
        if (e.currentTarget !== null) {
            var btn = e.currentTarget;
            let closestLi = e.currentTarget.closest("LI");
            if (closestLi !== null) {
                toggleClass(btn, "open");
                toggleClass(closestLi, "open");
                console.log("LI: " + closestLi.classList);
                // update aria-expanded as well
                if (closestLi.classList.contains("open")) {
                    btn.setAttribute("aria-expanded", true);
                    btn.setAttribute("aria-label", "Collapse Submenu")
                } else {
                    btn.setAttribute("aria-expanded", false);
                    btn.setAttribute("aria-label", "Expand Submenu")
                }
                console.log("btn attribute: " + btn.getAttribute("ariaExpanded"));
            }
        }
    })
    );

    const mobileMediaQuery = window.matchMedia('(max-width:771px)');
    mobileMediaQuery.addListener((e) => {
        if (e.matches) {
            // if we're moving to mobile, which is when this will match, then let's hide the desktop search panel if it exists
            let searchElement = document.querySelector(".home-search-panel");
            if (searchElement !== null) {
                if (searchElement.classList.contains("open")) {
                    searchElement.classList.remove("open");
                }
            }
            // and make sure we switch the icon back to the search icon instead of the close icon
            resetSearchPanels();
        }
    });

    const mediaQuery = window.matchMedia('(min-width:771px)');
    mediaQuery.addListener((e) => {
        if (e.matches) {
            // if we're moving to desktop, which is when this will match, then let's hide the mobile search panel if it exists
            let searchElement = document.querySelector(".home-mobile-search-panel");
            if (searchElement !== null) {
                if (searchElement.classList.contains("open")) {
                    searchElement.classList.remove("open");
                }
            }
            // and let's remove the open class on the header if it's there so it isn't stuck open when we return to mobile view
            let headerElement = document.querySelector(".home-header");
            if (headerElement !== null) {
                if (headerElement.classList.contains("open")) {
                    headerElement.classList.remove("open");
                }
            }
            // and reset the search panels
            resetSearchPanels();
        }
    });

    // Closes the mobile search panel
    document.querySelectorAll(".footer-links-header-container button").forEach(el => {
        el.addEventListener("click", (e) => {
            // toggle plus/minus class
            // show/hide the list of links
            toggleClass(el, "open");
            let navElement = e.target.closest("nav");
            toggleClass(navElement, "open");
           
        })
    });
    
}

function resetSearchPanels() {
    document.querySelectorAll(".search-icon-desktop> svg").forEach(el => el.classList.remove("close"));
    document.querySelector(".desktop-search-close-toggle").classList.add("close");
    document.querySelectorAll(".search-icon-mobile> svg").forEach(el => el.classList.remove("close"));
    document.querySelector(".mobile-search-close-toggle").classList.add("close");
}

// Utility function to toggle a class... probably should move to a general utility class
function toggleClass(element, className) {
    if (element.classList.contains(className)) {
        element.classList.remove(className);
    }
    else {
        element.classList.add(className);
    }
}

export { initMobileNav };