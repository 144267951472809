import { initMobileNav } from './mobile-nav.js';

function homeInit() {
    initMobileNav();
   
}

// wait for the page to load before we initialize the functionality
if (document.readyState === "complete" || document.readyState === "interactive") {
    setTimeout(1, homeInit);
} else {
    document.addEventListener("DOMContentLoaded", homeInit);
}